import { useTranslation } from "next-i18next";
import Head from "next/head";

type TwitterCard = "summary" | "summary_large_image";

interface Label {
  /** Title of the Twitter Card URL preview label */
  title: string;
  /** Text showing under the title */
  content: string;
}

interface Props {
  /** Relative path of the current page */
  path: string;
  /**
   * Title to show on the URL preview
   *
   * @defaultValue `"Pianity - Collect unique artworks from your favourite musicians"`
   *
   * Set to `null` to remove
   */
  title?: string | null;
  /** Set to true to not add "- Pianity" after the title */
  removeTitleSuffix?: boolean;
  /**
   * Short text to show on the URL preview
   *
   * Set to `null` to remove
   */
  description?: string | null;
  /** URL of the image to show on the URL preview
   *
   * Set to `null` to not show default image
   *
   * @defaultValue `"https://pianity.com/assets/sharing/pianity.jpg"`
   */
  image?: string | null;
  /** Format of the Twitter Card
   * @defaultValue "summary_large_image"
   */
  twitterCard?: TwitterCard;
  /** Object for Twitter Card label 1 */
  label1?: Label;
  /** Object for Twitter Card label 2 */
  label2?: Label;
}

/**
 * Creates HTML meta tags for Facebook's OpenGraph and Twitter Cards
 * used to preview URLs on the web
 *
 * @remarks
 * Must be rendered inside NextJs' <Head /> component
 */
export default function HeadUrlMeta({
  path,
  title,
  removeTitleSuffix,
  description,
  image,
  twitterCard,
  label1,
  label2,
}: Props) {
  const { t } = useTranslation("common");
  const baseUrl = "https://pianity.com";
  const fullUrl = baseUrl + path;

  return (
    <Head>
      {/* <!-- canonical URL --> */}
      <link rel="canonical" href={fullUrl} key="canonical" />
      <link rel="alternate" href={fullUrl} hrefLang="x-default" />
      <link rel="alternate" href={fullUrl} hrefLang="en" />
      <link rel="alternate" href={baseUrl + "/fr" + path} hrefLang="fr" />

      {/* <!-— facebook open graph tags --> */}
      <meta property="og:type" content="website" key="og-type" />
      <meta property="og:url" content={fullUrl} key="og-url" />
      {title !== null && (
        <meta
          property="og:title"
          content={
            (title ?? t("common.utils.default-metadata.title")) + (removeTitleSuffix ? "" : " - Pianity")
          }
          key="og-title"
        />
      )}
      {description !== null && (
        <meta
          property="og:description"
          content={description ?? t("common.utils.default-metadata.description")}
          key="og-description"
        />
      )}
      {image !== null && (
        <meta
          property="og:image"
          content={image ?? "https://pianity.com/assets/sharing/pianity.jpg"}
          key="og-image"
        />
      )}

      {/* <!-— twitter card tags additive with the og: tags --> */}
      <meta name="twitter:card" content={twitterCard ?? "summary_large_image"} key="tc-card" />
      <meta name="twitter:domain" content="pianity.com" key="tc-domain" />
      <meta name="twitter:site" content="@pianitynft" key="tc-site" />
      {title !== null && (
        <meta
          name="twitter:title"
          content={
            (title ?? t("common.utils.default-metadata.title")) + (removeTitleSuffix ? "" : " - Pianity")
          }
          key="tc-title"
        />
      )}
      {description !== null && (
        <meta
          name="twitter:description"
          content={description ?? t("common.utils.default-metadata.description")}
          key="tc-description"
        />
      )}
      {image !== null && (
        <meta
          name="twitter:image"
          content={image ?? "https://pianity.com/assets/sharing/pianity-twitter.jpg"}
          key="tc-image"
        />
      )}
      <meta name="twitter:url" content={fullUrl} key="tc-url" />
      {label1 && <meta name="twitter:label1" content={label1.title} key="tc-label1" />}
      {label1 && <meta name="twitter:data1" content={label1.content} key="tc-data1" />}
      {label2 && <meta name="twitter:label2" content={label2.title} key="tc-label2" />}
      {label2 && <meta name="twitter:data2" content={label2.content} key="tc-data2" />}
    </Head>
  );
}

import { GetStaticPropsContext } from "next";
import parseHtml from "html-react-parser";
import Head from "next/head";

import useWebflowScripts from "@/utils/hooks/webflow/useWebflowScripts";
import { getWebflowPage, WebflowProps } from "@/utils/webflow/server";
import { parseHEADOptions, parseOptions } from "@/utils/webflow/client";
import HeadUrlMeta from "@/components/HeadUrlMeta";
import RegisterCTAs from "@/components/RegisterCTAs";
import LoggedOutOnly from "@/components/LoggedOutOnly";

export default function LandingPage(props: WebflowProps) {
  useWebflowScripts(props);

  if (!props.bodyContent) return null;

  return (
    <>
      <Head>
        <link rel="preload" href="/styles/wf-default.css" as="style" />
        {props.headContent && parseHtml(props.headContent, parseHEADOptions)}
        {/* eslint-disable-next-line @next/next/no-css-tags */}
        <link rel="stylesheet" href="/styles/wf-default.css" />
      </Head>
      <HeadUrlMeta title={null} description={null} path="/about" />

      <div className="wf-default">{parseHtml(props.bodyContent, parseOptions)}</div>
      <LoggedOutOnly>
        <RegisterCTAs />
      </LoggedOutOnly>
    </>
  );
}

export async function getStaticProps(ctx: GetStaticPropsContext) {
  if (!process.env.WEBFLOW_URI) {
    return { notFound: true, revalidate: 60 * 5 };
  }

  const fetchUrl = process.env.WEBFLOW_URI + "/template/1/homepage";

  return await getWebflowPage(fetchUrl, {}, ctx.locale, ["homepage"]);
}
